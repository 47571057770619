export const getRearCameraMaxResolution = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const rearCamera = devices.find(
    (device) => device.kind === 'videoinput' && device.label.toLowerCase().includes('back'),
  );

  if (!rearCamera) {
    throw new Error('Rear camera not found');
  }

  const stream = await navigator.mediaDevices.getUserMedia({
    video: { deviceId: rearCamera.deviceId, facingMode: 'environment' },
  });

  const [videoTrack] = stream.getVideoTracks();
  const { width, height } = videoTrack.getSettings();

  videoTrack.stop(); // Stop the camera stream once we have the desired information

  return { width, height };
};
