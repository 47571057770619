import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { getRearCameraMaxResolution } from './getRearCameraMaxResolution';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const initApp = async () => {
  let videoConstraints;

  try {
    const { width, height } = await getRearCameraMaxResolution();
    videoConstraints = {
      facingMode: 'environment',
      width: { ideal: width },
      height: { ideal: height },
    };
  } catch (error) {
    console.warn(
      'Failed to get the rear camera max resolution, using iPhone 12 values as fallback:',
      error,
    );
    // Fallback to iPhone 12 values
    videoConstraints = {
      facingMode: 'environment',
      width: { ideal: 4032 },
      height: { ideal: 3024 },
    };
  }
  root.render(
    <React.StrictMode>
      <App videoConstraints={videoConstraints} />
    </React.StrictMode>,
  );
};
initApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
