import { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import './App.css';

interface AppProps {
  videoConstraints?: boolean | MediaTrackConstraints | undefined;
}

const App = ({ videoConstraints }: AppProps) => {
  const [clicked, setClicked] = useState(false);
  const webcamRef = useRef<Webcam>(null);

  const captureScreenshot = useCallback(() => {
    if (!webcamRef.current) return;

    const screenshotData = webcamRef.current.getScreenshot() as string;

    // Create a canvas element to resize the image
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set the canvas dimensions to the desired width and height
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const desiredWidth = (videoConstraints as MediaTrackConstraints)?.width?.ideal;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const desiredHeight = (videoConstraints as MediaTrackConstraints)?.height?.ideal;
    canvas.width = desiredWidth;
    canvas.height = desiredHeight;

    // Create an image element to load the captured screenshot
    const img = new Image();
    img.src = screenshotData;

    // Draw the image onto the canvas, resizing it to the desired dimensions
    img.onload = () => {
      ctx?.drawImage(img, 0, 0, desiredWidth, desiredHeight);

      // Get the resized image data from the canvas
      const resizedImageData = canvas.toDataURL('image/jpeg', 1); // Increase the second parameter for better quality (max is 1)

      try {
        sendImageToAPI(resizedImageData);
      } catch (error) {
        console.log(error);
      }
    };
  }, [webcamRef]);

  useEffect(() => {
    console.log({ clicked });
  }, [clicked]);

  const handleClick = () => {
    captureScreenshot();
    setClicked(true);

    setTimeout(() => {
      window.close();
    }, 1000);
  };

  // const capture = useCallback(() => {
  //   if (!webcamRef.current) return;

  //   const imageSrc = webcamRef.current.getScreenshot({
  //     screenshotQuality: 1, // Increase the value for better quality (max is 1)
  //   });
  //   if (!imageSrc) return;

  //   sendImageToAPI(imageSrc);
  // }, [webcamRef]);

  const sendImageToAPI = async (imageSrc: string): Promise<void> => {
    console.log('about to send request');
    try {
      // Convert data URL to Blob
      const byteString = atob(imageSrc.split(',')[1]);
      const mimeString = imageSrc.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });

      const formData = new FormData();
      formData.append('file', blob);

      const apiResponse = await fetch(process.env.REACT_APP_API_URL as string, {
        method: 'POST',
        body: formData,
      });
      console.log(apiResponse);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="container">
        <Webcam
          className="webcam"
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width="100%"
          height="100%"
          videoConstraints={videoConstraints}
          playsInline
        />
        <div>
          {!clicked && (
            <button className="captureButton" onClick={handleClick}>
              Mark Paper
            </button>
          )}
          {clicked && <div className="message">All done!</div>}
        </div>
      </div>
    </>
  );
};

export default App;
